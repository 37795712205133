@import '../../../../assets/scss/medias';

.Container {
    width: 100%;
    height: 100%;
    position: relative;
    margin: 0 auto;

    @include media_ml {
        width: 100%;
        max-width: 640px;
    }
}
