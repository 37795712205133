.FullscreenCarousel {
    width: 100%;
    height: 100%;

    :global(.swiper-slide) {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    :global(.swiper-wrapper) {
        transition-timing-function: ease;
    }
}
