.ShopPage {
    :global(.slick-track) {
        display: flex !important;
    }

    :global(.slick-slide) {
        height: inherit !important;

        & > div {
            height: 100% !important;
        }
    }
}
