@import '../../../assets/scss/medias';

.ButtonRaw {
    text-align: left;
    cursor: pointer;
    border-radius: 0;
    height: auto;
    display: block;
    background: none;
    border: none;
    padding: 0;
    position: relative;
    color: inherit;
    margin: 0;
    align-items: center;

    @include media_ml {
        padding: 0;
    }
}

:global(#root) .ButtonRaw {
    text-decoration: none;
}

.Button {
    align-items: center;
    display: flex;
    font-size: 10px;
    font-stretch: normal;
    font-style: normal;
    height: 40px;
    justify-content: center;
    letter-spacing: 2px;
    line-height: 14px;
    margin: 10px 0;
    padding: 10px 24px 12px;
    text-align: center;
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
}

.Underline {
    text-decoration: underline;
}

.Disabled {
    background-color: var(--color-dove-gray-a30);
    cursor: default;
}
