@import '../../assets/scss/medias';

@include media_md {
    .setting-icon-accent {
        .icon-accent-stroke {
            stroke: var(--color-romance);
        }

        .icon-accent-fill {
            fill: var(--color-romance);
        }

        .icon-usage-accent-fill {
            fill: var(--color-mischka);
        }
    }
}

.slick-slide {
    .ica--device-description {
        display: none;
    }

    &.slick-current {
        .ica--device-description {
            display: block;
        }
    }
}

[dir='rtl'] .slick-slide {
    float: left !important;
}
