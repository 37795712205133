@import '../../../../assets/scss/medias';
@import '../../../../assets/scss/mixins';

.AppLoaderContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    outline: none;
    box-sizing: border-box;
}

.AppLoader {
    position: relative;
    width: 44px;
    height: 44px;

    div {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 3px solid var(--color-tertiary);
        border-radius: 50%;
        animation: appLoaderAnim 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: var(--color-tertiary) transparent transparent transparent;
    }

    @include media_ml {
        width: 20px;
        height: 20px;

        div {
            border-width: 2px;
            border-color: var(--color-jumbo) transparent transparent transparent;
        }
    }
}

.LoaderMessage {
    margin-top: 20px;
    color: var(--color-primary);
    text-align: center;
    white-space: pre-line;
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: 0;
}

.AppLoader div:nth-child(1) {
    animation-delay: -0.45s;
}

.AppLoader div:nth-child(2) {
    animation-delay: -0.3s;
}

.AppLoader div:nth-child(3) {
    animation-delay: -0.15s;
}

@keyframes appLoaderAnim {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.Overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 120;
    background-color: var(--color-romance);
    opacity: 0.95;
}

.Transparent {
    z-index: 110;
    background-color: transparent;
}

.LoaderCentered {
    @include absolut-centered();
}
