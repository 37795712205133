$screen-md: 744px;
$screen-ml: 1133px;
$screen-lg: 1280px;

@mixin media_md {
    @media only screen and (min-width: $screen-md) {
        @content;
    }
}

@mixin media_ml {
    @media only screen and (min-width: $screen-ml) {
        @content;
    }
}

@mixin media_lg {
    @media only screen and (min-width: $screen-lg) {
        @content;
    }
}

@mixin media_custom($min-width, $max-width) {
    @media only screen and (min-width: $min-width) and (max-width: $max-width) {
        @content;
    }
}

@mixin media_landscape {
    @media (orientation: landscape) {
        @content;
    }
}
