@layer components {
    .title-3 {
        @apply mb-3 text-28 leading-14;
    }

    .title-4 {
        @apply mb-3 text-24 leading-13;
    }

    .title-5 {
        @apply mb-3 text-20 leading-12;
    }

    .title-6 {
        @apply mb-3 text-18 leading-155;
    }

    .body-14 {
        @apply text-14 leading-143 tracking-04;
    }

    .body-16 {
        @apply text-16 leading-15;
    }

    .radio {
        @apply relative isolate cursor-pointer;

        .checkmark {
            @apply relative h-6 w-6 min-w-[24px] rounded-full border border-primary;
        }

        input {
            @apply z-[1] cursor-pointer opacity-0;
            left: 0 !important; // '!important' is needed to override 'left: -9999px' on ae market
            top: 0 !important;
            position: absolute !important;
            width: 100% !important;
            height: 100% !important;
            margin: 0;
        }

        input:checked ~ .checkmark:after {
            @apply absolute left-1/2 top-1/2 h-3 w-3 -translate-x-1/2 -translate-y-1/2 rounded-full bg-primary;
            content: '';
        }
    }

    .mt-safe-offset-sticky-header {
        // Project build fails if @apply ends with "];" so "lg:box-border" is used to avoid this (same below).
        // lg: prefix is used to place class at the end when sorting, box-border is value that will not affect
        // ui or conflict with other classes
        @apply mt-safe-offset-[var(--sticky-header-height)] lg:box-border;
    }

    .mt-safe-or-sticky-header {
        @apply mt-safe-or-[var(--sticky-header-height)] lg:box-border;
    }

    .pt-safe-offset-sticky-header {
        @apply pt-safe-offset-[var(--sticky-header-height)] lg:box-border;
    }

    .pt-sticky-header {
        @apply pt-[var(--sticky-header-height)] lg:box-border;
    }

    .top-safe-offset-sticky-header {
        @apply top-safe-offset-[var(--sticky-header-height)] lg:box-border;
    }
}
