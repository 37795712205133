.LogsIcon {
    position: absolute;
    right: 0;
    bottom: 0;
    cursor: pointer;
    padding: 5px 10px;
    font-size: 14px;
    background-color: var(--color-black-a10);
    color: var(--color-white);
    text-align: center;
    z-index: 200;
}

.LogContainer {
    display: flex;
    font-size: 12px;
}

.LogTime {
    width: 65px;
}

.Log {
    flex: 1;
    word-break: break-word;
}

.ClearLogsBtn {
    font-size: 12px !important;
    display: inline-block;
    cursor: pointer;
    border: 1px solid black;
    border-radius: 5px;
    padding: 2px 4px;
    margin-bottom: 20px;
    margin-right: 10px;
}

.LogsPopupOverlay {
    z-index: 200;
}

.LogsPopup {
    max-height: 90vh;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    text-align: left;
    padding: 60px;
}
