@import '../../../assets/scss/mixins';

.Thumbnail.Thumbnail {
    @include absolut-centered();
    object-fit: contain;
    opacity: 1;
    transition: opacity 0.3s;

    &.Hidden {
        opacity: 0;
    }
}

.Loader {
    z-index: 3;
}

.VideoContainer {
    width: 100%;
    height: 212px;
    display: block;
    margin: 0 auto;
    position: relative;

    @include media_md {
        height: 356px;
    }

    @include media_ml {
        height: 456px;
    }
}

.ControlButtonWrapper {
    align-items: center;
    background: var(--color-white);
    border-radius: 50%;
    top: 4.5%;
    cursor: pointer;
    height: 44px;
    justify-content: center;
    left: 4.5%;
    padding: 0;
    position: absolute;
    width: 44px;
    z-index: 2;
    display: none;

    &.Visible {
        display: flex;
    }

    @include media_ml {
        width: 40px;
        height: 40px;
    }
}

[dir='rtl'] .ControlButtonWrapper {
    left: unset;
    right: 4.5%;
}

.ControlButton {
    border-color: transparent transparent transparent #202020;
    border-style: double;
    border-width: 0 0 0 60px;
    box-sizing: border-box;
    cursor: pointer;
    display: block;
    height: 74px;
    position: absolute;
    transform: scale(0.25);
    transition: 100ms all ease;
    width: 0;

    &.Paused {
        border-style: solid;
        border-width: 37px 0 37px 60px;
        transform: translateX(2px) scale(0.25);
    }
}

[dir='rtl'] .ToggleActive {
    &.Paused {
        transform: translateX(-2px) scale(0.25);
    }
}
