.Carousel {
    // should be .slick-list > .slick-track (not just .slick-track) to increase
    // css specificity and override styles in ShopPage.module.scss
    :global(.slick-list) > :global(.slick-track) {
        display: block !important;
    }

    :global(.slick-dots) {
        & li {
            flex: 1;
        }
    }
}

.CarouselPreview {
    :global(.slick-slide) {
        padding: 0 8px;
    }

    :global(.slick-list) {
        margin: 0 -8px;
    }
}

.ProgressAnimation {
    & li {
        & span {
            width: 0;
        }

        &:global(.slick-active) {
            & span:global(.animated) {
                animation: progress linear forwards;
                animation-play-state: running;
            }

            & span:global(.paused) {
                animation-play-state: paused;
            }
        }
    }
}

@keyframes progress {
    0% {
        width: 0;
    }

    100% {
        width: 100%;
    }
}
