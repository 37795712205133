.Carousel {
    &:global(.swiper) {
        overflow: visible;
    }

    :global(.swiper-wrapper) {
        height: auto;
    }

    :global(.swiper-slide) {
        width: auto;
        height: inherit !important;
    }
}
