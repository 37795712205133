@import '../../../assets/scss/medias';

.Toggle {
    position: relative;
    width: 48px;
    height: 28px;
    background-color: var(--color-romance);
    border-radius: 100px;
    border: 1px solid var(--color-primary);
    cursor: pointer;
    user-select: none;

    @include media_md {
        width: 56px;
        height: 32px;
    }

    &.Active {
        .Slider {
            background-color: var(--color-tertiary);
            transition: 0.2s ease;
            transform: translateX(20px);

            @include media_md {
                transform: translateX(24px);
            }
        }
    }

    &:focus {
        // override styles for pt market
        border: 1px solid var(--color-primary) !important;
    }
}

.Slider {
    width: 22px;
    height: 22px;
    position: absolute;
    top: 2px;
    left: 2px;
    border-radius: 50%;
    background-color: var(--color-primary);
    transition: 0.2s ease;
    transform: translateX(0);
    display: flex;
    justify-content: center;
    align-items: center;

    @include media_md {
        width: 24px;
        height: 24px;
        top: 3px;
        left: 3px;
    }
}

[dir='rtl'] {
    .Slider {
        right: 2px;

        @include media_md {
            right: 4px;
        }
    }

    .Toggle.Active {
        .Slider {
            transform: translateX(-20px);
        }
    }
}
