@layer utilities {
    .text-start {
        @apply text-left;
    }

    [dir='rtl'] .text-start {
        @apply text-right;
    }

    .text-end {
        @apply text-right;
    }

    [dir='rtl'] .text-end {
        @apply text-left;
    }

    .body-link {
        a {
            @apply font-bold underline;
        }
    }

    .line-clamp-2 {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .shrink-0 {
        flex-shrink: 0;
    }

    .columns-count-2 {
        column-count: 2;
    }

    .columns-span {
        span {
            padding: 0 0 20px 0;
            display: inline-block;
        }

        :last-child {
            display: inline;
        }
    }

    .stroke-bright {
        stroke: var(--color-romance);
    }

    .rotate-x-180 {
        transform: rotateX(180deg);
    }

    .rotate-y-180 {
        transform: rotateY(180deg);
    }

    .no-scrollbar {
        &::-webkit-scrollbar {
            display: none;
        }
    }

    .hyphens-auto {
        -webkit-hyphens: auto;
        -ms-hyphens: auto;
        hyphens: auto;
    }

    .drop-shadow {
        filter: drop-shadow(16px 12px 1px var(--color-black-a40));
    }

    .px-layout {
        @apply px-4 md:px-8 ml:px-16;
    }

    .translate-z-1 {
        transform: translateZ(1px);
    }

    .min-h-screen {
        min-height: 100vh; /* Fallback */
        min-height: 100dvh;
    }

    .max-h-screen {
        max-height: 100vh; /* Fallback */
        max-height: 100dvh;
    }

    .h-screen {
        height: 100vh; /* Fallback */
        height: 100dvh;
    }

    .ica-standalone {
        .min-h-screen {
            min-height: 100lvh;
        }

        .max-h-screen {
            max-height: 100lvh;
        }

        .h-screen {
            height: 100lvh;
        }
    }

    .flex-center {
        justify-content: center;
        align-items: center;
    }
}
