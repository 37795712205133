@mixin defaultColor {
    --button-color: var(--color-primary);
    --button-bg-color: var(--color-romance);
    --box-shadow-color: #b8b7bb;
}

@mixin activeColor {
    --button-color: var(--color-romance);
    --button-bg-color: transparent;
    --box-shadow-color: transparent;
}

.AdvancedDiagnosticIssueList {
    @include defaultColor();
    position: relative;
    text-align: left;
    white-space: pre-line;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    min-height: 96px;
    padding: 24px;
    border-radius: 3px;
    color: var(--button-color);
    background-color: var(--button-bg-color);
    box-shadow: inset 0 0 0 1.5px var(--box-shadow-color);
    transition:
        background 0.2s,
        box-shadow 0.3s ease-out,
        color 0.2s;
    overflow: hidden;
    z-index: 1;
    cursor: pointer;

    &:hover,
    &:focus-visible {
        @include activeColor();
        transition:
            background 0.15s 0.2s,
            box-shadow 0.15s 0.25s ease-out,
            color 0.2s;

        svg path {
            transition: stroke 0.1s ease-out;
            stroke: var(--color-romance);
        }

        &:after {
            transform: translate(-10%, -50%) skew(-30deg);
        }
    }

    &:after {
        content: '';
        position: absolute;
        background-color: var(--color-primary);
        top: 50%;
        left: 0;
        width: 120%;
        height: 120%;
        z-index: -1;
        transition: transform 0.25s ease-out;
        transform: translate(-101%, -50%) skew(0deg);
    }
}

[dir='rtl'] .AdvancedDiagnosticIssueList {
    text-align: right;
}
