@import '../../../assets/scss/medias';

.Dots {
    position: relative;
    width: 100%;
    padding: 0;
    margin: 0;
    text-align: center;

    :global(li.slick-active button::before) {
        opacity: 1;
    }

    li {
        margin: 0 8px;
        width: auto;
        height: auto;
        padding: 0;
        cursor: pointer;
        display: inline-block !important; // RS: !important is used to override DCS styles

        &:last-child {
            margin-right: 0;
        }

        button {
            width: auto;
            height: auto;
            padding: 0;
            font-size: 0;
            line-height: 0;
            display: block;
            cursor: pointer;
            color: transparent;
            border: 0;
            outline: none;
            background: transparent;
            position: relative;

            &:focus {
                outline: none !important;
            }

            &:before {
                content: '\2022';
                position: relative;
                opacity: 0.25;
                color: var(--color-primary);
                -webkit-font-smoothing: antialiased;
                font-size: 18px;
                font-family: Arial, Helvetica, sans-serif;
            }
        }
    }
}

.DotsFocus {
    @include media_ml {
        button:hover::before,
        button:focus::before {
            opacity: 0.5;
        }
    }
}
