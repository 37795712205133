@import '../../../../assets/scss/medias';

.AdvancedDiagnosticCarousel {
    :global(.slick-track) {
        display: flex !important;
    }

    :global(.slick-slide) {
        height: auto;
        cursor: pointer;

        & > div {
            height: 100%;
            padding: 0 8px;
        }

        & > div ~ div {
            height: auto;
            padding: unset;
        }
    }
}

.SingleSlide {
    :global(.slick-track) {
        justify-content: center;
        margin-bottom: 8px;
    }
}
