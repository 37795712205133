@import '../scss/medias';

@layer base {
    :root {
        --color-input-border: 255deg 3% 73%; //#b8b7bb;
        --color-black: 0deg 0% 0%; //#00000066

        --sidebar-width: 136px;
        --profile-sidebar-width: 264px;
        --devices-popup-sidebar-width: 392px;
        --sticky-header-height: 64px;

        @include media_ml {
            --sidebar-width: 120px;
            --sticky-header-height: 80px;
        }

        @include media_lg {
            --sidebar-width: 136px;
        }
    }

    html {
        -webkit-tap-highlight-color: transparent;
    }

    #root {
        @apply body-16 text-center font-iqos font-normal text-primary;
    }

    *,
    ::before,
    ::after {
        box-sizing: border-box;
        border-width: 0;
        border-style: solid;
    }

    ::before,
    ::after {
        --tw-content: '';
    }

    a {
        color: inherit;
        text-decoration: inherit;
    }

    b,
    strong {
        font-weight: bolder;
        @apply font-bold;
    }

    :-moz-focusring {
        outline: auto;
    }

    :-moz-ui-invalid {
        box-shadow: none;
    }

    button,
    input,
    optgroup,
    select,
    textarea {
        font-family: inherit;
        font-size: 100%;
        font-weight: inherit;
        line-height: inherit;
    }

    button,
    [type='button'],
    [type='reset'],
    [type='submit'] {
        background-color: transparent;
        background-image: none;
    }

    blockquote,
    dl,
    dd,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    hr,
    figure,
    p,
    pre {
        margin: 0;
    }

    fieldset {
        margin: 0;
        padding: 0;
    }

    legend {
        padding: 0;
    }

    ol,
    ul,
    menu {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    img,
    svg,
    video,
    canvas,
    audio,
    iframe,
    embed,
    object {
        display: block;
        vertical-align: middle;
    }

    img,
    video {
        max-width: 100%;
        height: auto;
    }

    *:focus {
        outline: none;
    }

    *:focus-visible {
        outline: 1px dotted #212121;
        outline: 5px auto -webkit-focus-ring-color;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        // font-iqos is needed to override h1 styles on "mv" market
        @apply font-iqos font-bold tracking-0;
        margin-block-start: 0;
        margin-block-end: 0;
    }

    h1,
    h2 {
        @apply title-3;
    }

    h4 {
        @apply title-4;
    }

    h5 {
        @apply title-5;
    }

    h6 {
        @apply title-6;
    }
}

.ica {
    &.touch {
        *:focus {
            outline: none;
        }
    }
}
