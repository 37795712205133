@import '../../../../assets/scss/medias';

.Arrow.Arrow {
    background-color: var(--color-romance);
    position: absolute;
    display: none;
    height: 48px;
    width: 48px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    border-radius: 100%;
    overflow: hidden;

    svg {
        margin: 0 auto;
    }

    @include media_ml {
        display: block;
    }

    &:hover,
    &:focus-visible {
        svg path {
            stroke: var(--color-romance);
            transition: stroke 0.15s ease-in-out;
        }

        &:after {
            transform: translate(-10%, -50%) skew(-30deg);
        }
    }

    &:after {
        content: '';
        position: absolute;
        background-color: var(--color-primary);
        top: 50%;
        left: 0;
        width: 120%;
        height: 120%;
        z-index: -1;
        transition: transform 0.25s ease-out;
        transform: translate(-101%, -50%) skew(0deg);
    }
}

.RightArrow {
    right: 16px;

    @include media_ml {
        right: 64px;
    }
}

.LeftArrow {
    left: 16px;
    svg {
        transform: rotate(180deg);
    }

    @include media_ml {
        left: 64px;
    }
}
