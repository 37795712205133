@import '../../../../assets/scss/medias';

.Button {
    padding: 12px 30px;
    border-radius: 24px;
    text-align: center;
    font-family: var(--ica-font-family);
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: 0.5px;
    color: var(--button-color);
    background-color: var(--button-bg-color);
    box-shadow: inset 0 0 0 1px var(--box-shadow-color);
    width: 100%;
    margin-top: 40px;
    position: relative;
    overflow: hidden;
    z-index: 1;

    @include media_ml {
        min-width: auto;
        width: auto;
        padding: 12px 32px;
    }

    &:after {
        content: '';
        position: absolute;
        background-color: var(--color-tertiary);
        top: 50%;
        left: 0;
        width: 120%;
        height: 120%;
        z-index: -1;
        transition: transform 0.25s ease-out;
        transform: translate(-101%, -50%) skew(0deg);
        will-change: transform;
    }
}

.Active,
.Loading {
    transition:
        background 0.2s,
        box-shadow 0.3s ease-out,
        color 0.2s;

    @media (hover: hover) {
        &:hover {
            --button-bg-color: transparent;
            --box-shadow-color: transparent;
            transition:
                background 0.15s 0.2s,
                box-shadow 0.15s 0.25s ease-out,
                color 0.2s;

            &:after {
                transform: translate(-10%, -50%) skew(-30deg);
            }
        }
    }

    @media (hover: none) {
        &:active {
            --button-bg-color: transparent;
            --box-shadow-color: transparent;
            transition:
                background 0.15s 0.2s,
                box-shadow 0.15s 0.25s ease-out,
                color 0.2s;

            &:after {
                transform: translate(-10%, -50%) skew(-30deg);
            }
        }
    }

    &:focus-visible {
        --button-bg-color: transparent;
        --box-shadow-color: transparent;
        transition:
            background 0.15s 0.2s,
            box-shadow 0.15s 0.25s ease-out,
            color 0.2s;

        &:after {
            transform: translate(-10%, -50%) skew(-30deg);
        }
    }
}

.Loading {
    cursor: default;

    &:after {
        transform: translate(-10%, -50%) skew(-30deg);
    }
}

.PrimaryLight {
    --button-color: var(--color-romance);
    --button-bg-color: var(--color-primary);
}

.SecondaryLight {
    --button-color: var(--color-primary);
}

.PrimaryDark {
    --button-color: var(--color-primary);
    --button-bg-color: var(--color-romance);
}

.SecondaryDark {
    --button-color: var(--color-romance);
    --button-bg-color: var(--color-primary);
}

.Quaternary {
    --button-color: var(--color-primary);
    --button-bg-color: var(--color-tertiary);
}

.Light {
    --box-shadow-color: var(--color-primary);
}

.Dark {
    --box-shadow-color: var(--color-romance);
}

.PrimaryColored {
    --button-color: var(--color-primary);
    --button-bg-color: var(--color-romance);
    --box-shadow-color: var(--color-romance);

    &:focus-visible,
    &:hover {
        --button-color: var(--color-romance);
    }

    &:after {
        background-color: var(--color-primary);
    }
}

.SecondaryColored {
    --button-color: var(--color-romance);
    --box-shadow-color: var(--color-romance);

    &:after {
        background-color: var(--color-primary);
    }
}

.SecondaryColoredLight {
    --button-color: var(--color-primary);
    --box-shadow-color: var(--color-primary);

    @media (hover: hover) {
        &:hover,
        &:focus-visible {
            --button-color: var(--color-romance);
        }
    }

    @media (pointer: coarse) {
        &:focus-visible {
            --button-color: var(--color-romance);
        }
    }
}

.Sandy {
    --button-color: var(--color-primary);
    --button-bg-color: var(--color-sandy);
    --button-shadow-color: var(--color-primary);
}

.Tertiary {
    display: inline-block;
    background-image: linear-gradient(90deg, var(--color-tertiary) 50%, var(--color-primary) 0);
    background-repeat: no-repeat;
    background-size: 200% 1px;
    background-position: 100% bottom;
    background-origin: content-box;
    transition: background-position 0.3s ease-out;
    padding-top: 10px;
    padding-bottom: 10px;

    &:hover {
        background-position: 0 bottom;
    }

    @include media_ml {
        padding: 0;
    }
}

.Disabled {
    --button-color: var(--color-romance);
    --button-bg-color: var(--color-grey);
    --box-shadow-color: var(--color-grey);
    cursor: default;
}

.Loader {
    width: 14px;
    height: 14px;
    position: absolute;
    transform: translateX(-50%) translateY(-50%);
    animation: loader 1.3s infinite ease-in-out;
    top: 50%;
    left: 50%;
}

@keyframes loader {
    0% {
        transform: translateX(-50%) translateY(-50%) rotate(0deg);
    }
    100% {
        transform: translateX(-50%) translateY(-50%) rotate(360deg);
    }
}
